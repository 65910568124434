import Option from './Option';
import { connections, walletConnectV2Connection } from 'connection';
import {
  type Connection,
  ConnectionType,
  type RecentConnectionMeta
} from 'connection/types';
import { useMemo } from 'react';

import { getIsMetaMaskWallet } from 'connection/utils';
import { isMobile } from 'utils/wallet';

import { useAppSelector } from 'state/hooks';

const mergeConnections = (
  connections: Connection[],
  eip6963Connections: Connection[]
) => {
  const hasEip6963Connections = eip6963Connections.length > 0;

  const displayedConnections = connections.filter(
    (c) =>
      c.shouldDisplay() &&
      (!isMobile ||
        c.type !== ConnectionType.INJECTED ||
        c === walletConnectV2Connection)
  );

  /*
  - Keeping the initial logic in case it is needed in the future
  if (!hasEip6963Connections) return displayedConnections;

  const allConnections = [
    ...displayedConnections.filter((c) => c.type !== ConnectionType.INJECTED)
  ];

  // By default, injected options should appear second in the list (below Uniswap wallet)
  allConnections.splice(1, 0, ...eip6963Connections);
  */

  // Ensure MetaMask is always first, followed by other injected wallets

  // Find MetaMask connection
  const metaMaskConnection = !isMobile
    ? connections.find(
        (c) => c.type === ConnectionType.INJECTED && getIsMetaMaskWallet()
      )
    : undefined;

  const otherConnections = displayedConnections.filter(
    (c) => c != metaMaskConnection
  );

  const allConnections = [
    ...(metaMaskConnection ? [metaMaskConnection] : []),
    ...otherConnections,
    ...(hasEip6963Connections ? eip6963Connections : [])
  ];

  return allConnections;
};

// TODO(WEB-3244) Improve ordering logic to make less brittle, as it is spread across connections/index.ts and here
/** Returns an array of all connection Options that should be displayed, where the recent connection is first in the array */
const getOrderedConnections = (
  connections: Connection[],
  recentConnection: RecentConnectionMeta | undefined
) => {
  const list: JSX.Element[] = [];
  for (const connection of connections) {
    if (!connection.shouldDisplay()) continue;
    const { name, rdns } = connection.getProviderInfo();

    // For eip6963 injectors, we need to check rdns in addition to connection type to ensure it's the recent connection
    const isRecent =
      connection.type === recentConnection?.type &&
      (!rdns || rdns === recentConnection.rdns);

    const option = (
      <Option key={name} connection={connection} isRecent={isRecent} />
    );

    // Place recent connection at top of list
    isRecent ? list.unshift(option) : list.push(option);
  }

  return list;
};

export const useOrderedConnections = () => {
  const { eip6963Connections, showDeprecatedMessage } = {
    eip6963Connections: [],
    showDeprecatedMessage: false
  };
  const recentConnection = useAppSelector(
    (state) => state.user.recentConnectionMeta
  );
  const orderedConnections = useMemo(() => {
    const allConnections = mergeConnections(connections, eip6963Connections);
    return getOrderedConnections(allConnections, recentConnection);
  }, [eip6963Connections, recentConnection]);

  return { orderedConnections, showDeprecatedMessage };
};
