import type { TradeType } from '@uniswap/sdk-core';

export interface SerializableTransactionReceipt {
  to: string;
  from: string;
  contractAddress: string;
  transactionIndex: number;
  blockHash: string;
  transactionHash: string;
  blockNumber: number;
  status?: number;
}

/**
 * Be careful adding to this enum, always assign a unique value (typescript will not prevent duplicate values).
 * These values is persisted in state and if you change the value it will cause errors
 */
export enum TransactionType {
  APPROVAL = 0,
  SWAP,
  STAKE,
  UNSTAKE,
  CLAIM,
  WRAP,
  ADD_LIQUIDITY,
  COLLECT_FEES,
  REMOVE_LIQUIDITY,
  BUY
}

interface BaseTransactionInfo {
  type: TransactionType;
}

export interface ApproveTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.APPROVAL;
  tokenAddress: string;
  spender: string;
  amount: string;
}

export interface StakeTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.STAKE;
  amount: string;
  tokenAddress: string;
}

export interface UnStakeTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.UNSTAKE;
  amount: string;
  tokenAddress: string;
}

export interface BuyTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.BUY;
  amount: string;
  tokenAddress: string;
}

interface BaseSwapTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.SWAP;
  tradeType: TradeType;
  inputCurrencyId: string;
  outputCurrencyId: string;
}

export interface ExactInputSwapTransactionInfo extends BaseSwapTransactionInfo {
  tradeType: TradeType.EXACT_INPUT;
  inputCurrencyAmountRaw: string;
  expectedOutputCurrencyAmountRaw: string;
  minimumOutputCurrencyAmountRaw: string;
  settledOutputCurrencyAmountRaw?: string;
}
export interface ExactOutputSwapTransactionInfo
  extends BaseSwapTransactionInfo {
  tradeType: TradeType.EXACT_OUTPUT;
  outputCurrencyAmountRaw: string;
  expectedInputCurrencyAmountRaw: string;
  maximumInputCurrencyAmountRaw: string;
}

export interface WrapTransactionInfo {
  type: TransactionType.WRAP;
  unwrapped: boolean;
  currencyAmountRaw: string;
  chainId?: number;
}

export interface ClaimTransactionInfo {
  type: TransactionType.CLAIM;
  recipient: string;
  uniAmountRaw?: string;
}

export interface AddLiquidityTransactionInfo {
  type: TransactionType.ADD_LIQUIDITY;
  createPool: boolean;
  baseCurrencyId: string;
  quoteCurrencyId: string;
  feeAmount: number;
  expectedAmountBaseRaw: string;
  expectedAmountQuoteRaw: string;
}

export interface CollectFeesTransactionInfo {
  type: TransactionType.COLLECT_FEES;
  currencyId0: string;
  currencyId1: string;
  expectedCurrencyOwed0: string;
  expectedCurrencyOwed1: string;
}

export interface RemoveLiquidityTransactionInfo {
  type: TransactionType.REMOVE_LIQUIDITY;
  baseCurrencyId: string;
  quoteCurrencyId: string;
  expectedAmountBaseRaw: string;
  expectedAmountQuoteRaw: string;
}

export type TransactionInfo =
  | ApproveTransactionInfo
  | StakeTransactionInfo
  | UnStakeTransactionInfo
  | BuyTransactionInfo
  | ExactOutputSwapTransactionInfo
  | ExactInputSwapTransactionInfo
  | ClaimTransactionInfo
  | WrapTransactionInfo
  | AddLiquidityTransactionInfo
  | CollectFeesTransactionInfo
  | RemoveLiquidityTransactionInfo;

export interface TransactionDetails {
  hash: string;
  receipt?: SerializableTransactionReceipt;
  lastCheckedBlockNumber?: number;
  addedTime: number;
  confirmedTime?: number;
  deadline?: number;
  from: string;
  info: TransactionInfo;
  nonce?: number;
  cancelled?: boolean;
}
