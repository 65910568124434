import { TransactionStatus } from 'graphql/data/__generated__/types-and-hooks';

import { TransactionType } from 'state/transactions/types';

const TransactionTitleTable: {
  [key in TransactionType]: { [state in TransactionStatus]: string };
} = {
  [TransactionType.SWAP]: {
    [TransactionStatus.Pending]: 'Swapping',
    [TransactionStatus.Confirmed]: 'Swapped',
    [TransactionStatus.Failed]: 'Swap failed'
  },
  [TransactionType.STAKE]: {
    [TransactionStatus.Pending]: 'Staking',
    [TransactionStatus.Confirmed]: 'Staked',
    [TransactionStatus.Failed]: 'Stake failed'
  },
  [TransactionType.UNSTAKE]: {
    [TransactionStatus.Pending]: 'Withdrawing',
    [TransactionStatus.Confirmed]: 'Withdrawn',
    [TransactionStatus.Failed]: 'Withdraw failed'
  },
  [TransactionType.WRAP]: {
    [TransactionStatus.Pending]: 'Wrapping',
    [TransactionStatus.Confirmed]: 'Wrapped',
    [TransactionStatus.Failed]: 'Wrap failed'
  },
  [TransactionType.ADD_LIQUIDITY]: {
    [TransactionStatus.Pending]: 'Adding liquidity',
    [TransactionStatus.Confirmed]: 'Added liquidity',
    [TransactionStatus.Failed]: 'Add liquidity failed'
  },
  [TransactionType.REMOVE_LIQUIDITY]: {
    [TransactionStatus.Pending]: 'Removing liquidity',
    [TransactionStatus.Confirmed]: 'Removed liquidity',
    [TransactionStatus.Failed]: 'Remove liquidity failed'
  },
  [TransactionType.COLLECT_FEES]: {
    [TransactionStatus.Pending]: 'Collecting fees',
    [TransactionStatus.Confirmed]: 'Collected fees',
    [TransactionStatus.Failed]: 'Collect fees failed'
  },
  [TransactionType.APPROVAL]: {
    [TransactionStatus.Pending]: 'Approving',
    [TransactionStatus.Confirmed]: 'Approved',
    [TransactionStatus.Failed]: 'Approval failed'
  },
  [TransactionType.CLAIM]: {
    [TransactionStatus.Pending]: 'Claiming',
    [TransactionStatus.Confirmed]: 'Claimed',
    [TransactionStatus.Failed]: 'Claim failed'
  },
  [TransactionType.BUY]: {
    [TransactionStatus.Pending]: 'Buying',
    [TransactionStatus.Confirmed]: 'Bought',
    [TransactionStatus.Failed]: 'Buy failed'
  }
};

export const CancelledTransactionTitleTable: {
  [key in TransactionType]: string;
} = {
  [TransactionType.SWAP]: 'Swap cancelled',
  [TransactionType.STAKE]: 'Stake cancelled',
  [TransactionType.UNSTAKE]: 'Withdraw cancelled',
  [TransactionType.WRAP]: 'Wrap cancelled',
  [TransactionType.ADD_LIQUIDITY]: 'Add liquidity cancelled',
  [TransactionType.REMOVE_LIQUIDITY]: 'Remove liquidity cancelled',
  [TransactionType.COLLECT_FEES]: 'Collect fees cancelled',
  [TransactionType.APPROVAL]: 'Approval cancelled',
  [TransactionType.CLAIM]: 'Claim cancelled',
  [TransactionType.BUY]: 'Buy cancelled'
};

const AlternateTransactionTitleTable: {
  [key in TransactionType]?: { [state in TransactionStatus]: string };
} = {
  [TransactionType.WRAP]: {
    [TransactionStatus.Pending]: 'Unwrapping',
    [TransactionStatus.Confirmed]: 'Unwrapped',
    [TransactionStatus.Failed]: 'Unwrap failed'
  },
  [TransactionType.APPROVAL]: {
    [TransactionStatus.Pending]: 'Revoking approval',
    [TransactionStatus.Confirmed]: 'Revoked approval',
    [TransactionStatus.Failed]: 'Revoke approval failed'
  }
};

export function getActivityTitle(
  type: TransactionType,
  status: TransactionStatus,
  alternate?: boolean
) {
  if (alternate) {
    const alternateTitle = AlternateTransactionTitleTable[type];
    if (alternateTitle !== undefined) return alternateTitle[status];
  }
  return TransactionTitleTable[type][status];
}
