import { Fraction, TradeType } from '@uniswap/sdk-core';
import { nativeOnChain } from 'constants/tokens';
import { BigNumber } from 'ethers/lib/ethers';
import JSBI from 'jsbi';

import { useCurrency, useToken } from 'hooks/Tokens';
import useENSName from 'hooks/useENSName';

import {
  type AddLiquidityTransactionInfo,
  type ApproveTransactionInfo,
  type BuyTransactionInfo,
  type ClaimTransactionInfo,
  type CollectFeesTransactionInfo,
  type ExactInputSwapTransactionInfo,
  type ExactOutputSwapTransactionInfo,
  type RemoveLiquidityTransactionInfo,
  type StakeTransactionInfo,
  type TransactionInfo,
  TransactionType, UnStakeTransactionInfo,
  type WrapTransactionInfo
} from 'state/transactions/types';

const formatAmount = (
  amountRaw: string,
  decimals: number,
  sigFigs: number
): string =>
  new Fraction(
    amountRaw,
    JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(decimals))
  ).toSignificant(sigFigs);

const FormattedCurrencyAmount = ({
  rawAmount,
  symbol,
  decimals,
  sigFigs
}: {
  rawAmount: string;
  symbol: string;
  decimals: number;
  sigFigs: number;
}) => (
  <>
    {formatAmount(rawAmount, decimals, sigFigs)} {symbol}
  </>
);

const FormattedCurrencyAmountManaged = ({
  rawAmount,
  currencyId,
  sigFigs = 6
}: {
  rawAmount: string;
  currencyId: string;
  sigFigs: number;
}) => {
  const currency = useCurrency(currencyId);
  return currency ? (
    <FormattedCurrencyAmount
      rawAmount={rawAmount}
      decimals={currency.decimals}
      sigFigs={sigFigs}
      symbol={currency.symbol ?? '???'}
    />
  ) : null;
};

const ClaimSummary = ({
  info: { recipient, uniAmountRaw }
}: {
  info: ClaimTransactionInfo;
}) => {
  const { ENSName } = useENSName();
  return typeof uniAmountRaw === 'string' ? (
    <>
      Claim{' '}
      <FormattedCurrencyAmount
        rawAmount={uniAmountRaw}
        symbol='UNI'
        decimals={18}
        sigFigs={4}
      />{' '}
      for {ENSName ?? recipient}
    </>
  ) : (
    <>Claim UNI reward for {ENSName ?? recipient}</>
  );
};

const ApprovalSummary = ({ info }: { info: ApproveTransactionInfo }) => {
  const token = useToken(info.tokenAddress);

  return BigNumber.from(info.amount)?.eq(0) ? (
    <>Revoke {token?.symbol}</>
  ) : (
    <>Approve {token?.symbol}</>
  );
};

const WrapSummary = ({
  info: { chainId, currencyAmountRaw, unwrapped }
}: {
  info: WrapTransactionInfo;
}) => {
  const native = chainId ? nativeOnChain(chainId) : undefined;

  if (unwrapped) {
    return (
      <>
        Unwrap{' '}
        <FormattedCurrencyAmount
          rawAmount={currencyAmountRaw}
          symbol={native?.wrapped?.symbol ?? 'WETH'}
          decimals={18}
          sigFigs={6}
        />{' '}
        to {native?.symbol ?? 'ETH'}
      </>
    );
  } else {
    return (
      <>
        Wrap{' '}
        <FormattedCurrencyAmount
          rawAmount={currencyAmountRaw}
          symbol={native?.symbol ?? 'ETH'}
          decimals={18}
          sigFigs={6}
        />{' '}
        to {native?.wrapped?.symbol ?? 'WETH'}
      </>
    );
  }
};

const CollectFeesSummary = ({
  info: { currencyId0, currencyId1 }
}: {
  info: CollectFeesTransactionInfo;
}) => {
  const currency0 = useCurrency(currencyId0);
  const currency1 = useCurrency(currencyId1);

  return (
    <>
      Collect {currency0?.symbol}/{currency1?.symbol} fees
    </>
  );
};

const RemoveLiquiditySummary = ({
  info: {
    baseCurrencyId,
    quoteCurrencyId,
    expectedAmountBaseRaw,
    expectedAmountQuoteRaw
  }
}: {
  info: RemoveLiquidityTransactionInfo;
}) => (
  <>
    Remove{' '}
    <FormattedCurrencyAmountManaged
      rawAmount={expectedAmountBaseRaw}
      currencyId={baseCurrencyId}
      sigFigs={3}
    />{' '}
    and{' '}
    <FormattedCurrencyAmountManaged
      rawAmount={expectedAmountQuoteRaw}
      currencyId={quoteCurrencyId}
      sigFigs={3}
    />
  </>
);

const AddLiquiditySummary = ({
  info: { createPool, quoteCurrencyId, baseCurrencyId }
}: {
  info: AddLiquidityTransactionInfo;
}) => {
  const baseCurrency = useCurrency(baseCurrencyId);
  const quoteCurrency = useCurrency(quoteCurrencyId);

  return createPool ? (
    <>
      Create pool and add {baseCurrency?.symbol}/{quoteCurrency?.symbol} V3
      liquidity
    </>
  ) : (
    <>
      Add {baseCurrency?.symbol}/{quoteCurrency?.symbol} V3 liquidity
    </>
  );
};

const SwapSummary = ({
  info
}: {
  info: ExactInputSwapTransactionInfo | ExactOutputSwapTransactionInfo;
}) => {
  if (info.tradeType === TradeType.EXACT_INPUT) {
    return (
      <>
        Swap exactly{' '}
        <FormattedCurrencyAmountManaged
          rawAmount={info.inputCurrencyAmountRaw}
          currencyId={info.inputCurrencyId}
          sigFigs={6}
        />{' '}
        for{' '}
        <FormattedCurrencyAmountManaged
          rawAmount={
            info.settledOutputCurrencyAmountRaw ??
            info.expectedOutputCurrencyAmountRaw
          }
          currencyId={info.outputCurrencyId}
          sigFigs={6}
        />
      </>
    );
  } else {
    return (
      <>
        Swap{' '}
        <FormattedCurrencyAmountManaged
          rawAmount={info.expectedInputCurrencyAmountRaw}
          currencyId={info.inputCurrencyId}
          sigFigs={6}
        />{' '}
        for exactly{' '}
        <FormattedCurrencyAmountManaged
          rawAmount={info.outputCurrencyAmountRaw}
          currencyId={info.outputCurrencyId}
          sigFigs={6}
        />
      </>
    );
  }
};

const StakeSummary = ({ info }: { info: StakeTransactionInfo }) => (
  <>Stake {info.amount}</>
);

const UnstakeSummary = ({ info }: { info: UnStakeTransactionInfo }) => (
  <>Withdraw {info.amount}</>
);

const BuySummary = ({ info }: { info: BuyTransactionInfo }) => (
  <>Buy {info.amount}</>
);

export const TransactionSummary = ({ info }: { info: TransactionInfo }) => {
  switch (info.type) {
    case TransactionType.ADD_LIQUIDITY:
      return <AddLiquiditySummary info={info} />;

    case TransactionType.CLAIM:
      return <ClaimSummary info={info} />;

    case TransactionType.SWAP:
      return <SwapSummary info={info} />;

    case TransactionType.APPROVAL:
      return <ApprovalSummary info={info} />;

    case TransactionType.WRAP:
      return <WrapSummary info={info} />;

    case TransactionType.COLLECT_FEES:
      return <CollectFeesSummary info={info} />;

    case TransactionType.REMOVE_LIQUIDITY:
      return <RemoveLiquiditySummary info={info} />;

    case TransactionType.STAKE:
      return <StakeSummary info={info} />;

    case TransactionType.UNSTAKE:
      return <UnstakeSummary info={info} />;

    case TransactionType.BUY:
      return <BuySummary info={info} />;
  }
};
